import { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { ButtonIconOnly, Input, Select, Spinner } from '@netfront/ui-library';
import cx from 'classnames';

import { HTML_CONFIGURATION_OPTIONS_DEFAULT_SELECTED_VALUES } from './RulesView.constants';
import { getSelectOptions } from './RulesView.helpers';
import styles from './RulesView.module.css';

import { useApiKeyContext } from '../../../../contexts';
import {
  ICreateHtmlConfigurationOnCompletedResponse,
  IDeleteHtmlConfigurationOnCompletedResponse,
  useCreateHtmlConfiguration,
  useDeleteHtmlConfiguration,
  useToast,
} from '../../../../hooks';
import {
  HTML_CONFIGURATION_FEATURE_TYPES,
  HTML_CONFIGURATION_PROPERTY_TYPES,
  HTML_CONFIGURATION_TYPE_TYPES,
  IApiKey,
  IHtmlConfiguration,
} from '../../../../interfaces';

const {
  feature: FEATURE_DEFAULT_SELECTED_VALUE,
  property: PROPERTY_DEFAULT_SELECTED_VALUE,
  type: TYPE_DEFAULT_SELECTED_VALUE,
} = HTML_CONFIGURATION_OPTIONS_DEFAULT_SELECTED_VALUES;

const RulesView = () => {
  const { dispatch, state: apiKeys = [] } = useApiKeyContext();
  const { handleToastError } = useToast();

  const [apiKeyHtmlConfigurations, setApiKeyHtmlConfigurations] = useState<IHtmlConfiguration[]>([]);
  const [htmlConfigurationFeatureSelectedValue, setHtmlConfigurationFeatureSelectedValue] =
    useState<string>(FEATURE_DEFAULT_SELECTED_VALUE);
  const [htmlConfigurationPropertySelectedValue, setHtmlConfigurationPropertySelectedValue] =
    useState<string>(PROPERTY_DEFAULT_SELECTED_VALUE);
  const [htmlConfigurationTypeSelectedValue, setHtmlConfigurationTypeSelectedValue] = useState<string>(TYPE_DEFAULT_SELECTED_VALUE);
  const [htmlConfigurationValue, setHtmlConfigurationValue] = useState<string>();
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey>();
  const [selectedHtmlConfigurationId, setSelectedHtmlConfigurationId] = useState<number>();

  const handleApolloError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCreateHtmlConfigurationClick = () => {
    
    if (!(htmlConfigurationValue && selectedApiKey)) {
      return;
    }

    handleCreateHtmlConfiguration({
      apiKeyId: selectedApiKey.id,
      feature: htmlConfigurationFeatureSelectedValue as "PDF" | "SPEECH" | "TRANSLATE",
      property: htmlConfigurationPropertySelectedValue as "INCLUDE" | "EXCLUDE",
      type: htmlConfigurationTypeSelectedValue as "CLASS" | "ID" | "TAG",
      value: htmlConfigurationValue.toLowerCase().replace(/\s+/g, '-')
    });
  };

  const handleCreateHtmlConfigurationCompleted = (data?: ICreateHtmlConfigurationOnCompletedResponse) => {
    if (!(data && selectedApiKey)) {
      return;
    }

    const { htmlConfiguration } = data;

    const updatedHtmlConfigurations = [...apiKeyHtmlConfigurations, htmlConfiguration];

    dispatch({
      payload: {
        apiKeyId: selectedApiKey.id,
        htmlConfigurations: updatedHtmlConfigurations,
      },
      type: 'updateApiKeyHtmlConfigurations',
    });

    setHtmlConfigurationFeatureSelectedValue(FEATURE_DEFAULT_SELECTED_VALUE);
    setHtmlConfigurationPropertySelectedValue(PROPERTY_DEFAULT_SELECTED_VALUE);
    setHtmlConfigurationTypeSelectedValue(TYPE_DEFAULT_SELECTED_VALUE);
    setHtmlConfigurationValue('');
  };

  const handleCreateHtmlConfigurationError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const handleDeleteHtmlConfigurationClick = (id: number) => {
    handleDeleteHtmlConfiguration({
      id,
    });

    setSelectedHtmlConfigurationId(id);
  };

  const handleDeleteHtmlConfigurationCompleted = (data?: IDeleteHtmlConfigurationOnCompletedResponse) => {
    if (!(data && selectedApiKey)) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      dispatch({
        payload: {
          apiKeyId: selectedApiKey.id,
          htmlConfigurationId: Number(selectedHtmlConfigurationId),
        },
        type: 'removeHtmlConfigurationFromApiKey',
      });

      return;
    }

    const {
      MESSAGES: {
        ERROR: { UNEXPECTED },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(UNEXPECTED),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleDeleteHtmlConfigurationError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const handleHtmlConfigurationFeatureChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = event
    setHtmlConfigurationFeatureSelectedValue(value);
  };

  const handleHtmlConfigurationPropertyChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = event
    setHtmlConfigurationPropertySelectedValue(value);
  };

  const handleHtmlConfigurationTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { target: { value } } = event
    setHtmlConfigurationTypeSelectedValue(value);
  };

  const handleHtmlConfigurationValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setHtmlConfigurationValue(value);
  };

  const { handleCreateHtmlConfiguration, isLoading: isCreateHtmlConfigurationLoading = false } = useCreateHtmlConfiguration({
    onCompleted: handleCreateHtmlConfigurationCompleted,
    onError: handleCreateHtmlConfigurationError,
  });

  const { handleDeleteHtmlConfiguration, isLoading: isDeleteHtmlConfigurationLoading = false } = useDeleteHtmlConfiguration({
    onCompleted: handleDeleteHtmlConfigurationCompleted,
    onError: handleDeleteHtmlConfigurationError,
  });

  useEffect(() => {
    if (!selectedApiKey) {
      return;
    }

    const { htmlConfigurations = [] } = selectedApiKey;

    setApiKeyHtmlConfigurations(htmlConfigurations);
  }, [selectedApiKey]);

  useEffect(() => {
    if (!apiKeys.length) {
      return;
    }

    setSelectedApiKey(apiKeys.find(({ isSelected }) => isSelected));
  }, [apiKeys]);

  const isLoading = isCreateHtmlConfigurationLoading || isDeleteHtmlConfigurationLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />
      <div className={cx(styles['c-rules-view'])}>
        <section className="pb-8">
          <h3 className="mb-2">Rules</h3>
          <p>Please enter your inclusions or exclusions to control which content KANZI will translate and speak.</p>
        </section>

        <section className="pb-8">
          <Input
            id="html-configuration-value"
            labelText=""
            name="html-configuration-value"
            type="text"
            value={htmlConfigurationValue}
            onChange={handleHtmlConfigurationValueChange}
          />
        </section>

        <section className="pb-8">
          <div className={cx(styles['c-rules-view__html-configuration-options-container'])}>
            <div className={cx(styles['c-rules-view__html-configuration-class-id-tag'])}>
              <Select
                id="html-configuration-class-id-tag"
                labelText=""
                name="html-configuration-class-id-tag"
                options={getSelectOptions(HTML_CONFIGURATION_TYPE_TYPES)}
                value={htmlConfigurationTypeSelectedValue}
                onChange={handleHtmlConfigurationTypeChange}
              />
            </div>
            <div className={cx(styles['c-rules-view__html-configuration-exclude-include'])}>
              <Select
                id="html-configuration-exclude-include"
                labelText=""
                name="html-configuration-exclude-include"
                options={getSelectOptions(HTML_CONFIGURATION_PROPERTY_TYPES)}
                value={htmlConfigurationPropertySelectedValue}
                onChange={handleHtmlConfigurationPropertyChange}
              />
            </div>
            <div className={cx(styles['c-rules-view__html-configuration-speech-translate'])}>
              <Select
                id="html-configuration-speech-translate"
                labelText=""
                name="html-configuration-speech-translate"
                options={getSelectOptions(HTML_CONFIGURATION_FEATURE_TYPES)}
                value={htmlConfigurationFeatureSelectedValue}
                onChange={handleHtmlConfigurationFeatureChange}
              />
            </div>
          </div>
          <div className={cx(styles['c-rules-view__html-configuration-add-icon-container'])}>
            <ButtonIconOnly
              iconId="id_add_rectangle_icon"
              isIconBorderVisible={false}
              text=""
              onClick={() => handleCreateHtmlConfigurationClick()}
            />
          </div>
        </section>

        <section className="pb-8">
          <div className={cx(styles['c-rules-view__html-configurations-container'])}>
            {apiKeyHtmlConfigurations.map(({ feature, id, property, type, value }) => {
              return (
                <div
                  key={`html-configuration-${id}`}
                  className={cx(styles['c-rules-view__html-configuration-container'], {
                    [styles['c-rules-view__html-configuration--exclude']]: property === 'EXCLUDE',
                    [styles['c-rules-view__html-configuration--include']]: property === 'INCLUDE',
                  })}
                >
                  <div className={cx(styles['c-rules-view__html-configuration'])}>{`${type}:${value}:${feature}`.toLowerCase()}</div>
                  <div className={cx(styles['c-rules-view__html-configuration-delete-icon'])}>
                    <ButtonIconOnly
                      iconId="id_close_icon"
                      isIconBorderVisible={false}
                      text=""
                      onClick={() => handleDeleteHtmlConfigurationClick(id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export { RulesView };
